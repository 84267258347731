<template>
  <div v-if="selectedCliente">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <clientesForm
          clienteSubmit="Editar Cliente"
          :cliente="selectedCliente"
          @processCliente="updateCliente"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const clientesForm = () => import('@/layouts/components/Clientes/ClientesForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    clientesForm,
  },

  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('clientes', ['selectedCliente']),
  },
  created() {
    // Consultar autenticación del
    // console.log(this.selectedCliente)
  },
  methods: {
    ...mapActions({ editarCliente: 'clientes/updateClientes' }),
    updateCliente(cliente) {
      this.spinner = true
      this.editarCliente(cliente).then(() => {
        const errorClientes = store.state.clientes
        const errorMessage = errorClientes.errorMessage.errors
        if (!errorClientes.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cliente editado con éxito 👍',
              text: `El cliente "${cliente.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'clientes',
          })
        } else if (errorMessage.rut) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.rut[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorClientes.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
